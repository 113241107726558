import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './Header';
import firebase from './FirebaseConfig';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn
} from "mdb-react-ui-kit";

function Main() {
    const [accessToken, setAccessToken] = useState(null);
    const [validToken, setValidToken] = useState(false);
    const [loginBtnText, setLoginBtnText] = useState("login");
    const [uid, setUid] = useState("");
    const [quotes, setQuotes] = useState([]);
    const location = useLocation();
    const history = useNavigate();

    useEffect(() => {
        if (location.state !== null) {
            const accessToken = location.state.accessToken;
            setAccessToken(accessToken);
            verifyToken(accessToken);
        } else {
            setLoginBtnText("Login");
            history("/");
        }
    }, []);

    const getQuotes = async (token, user) => {
        console.log("getQuotes: " + token);
        try {
            let url = 'https://quotes.trkd.io/quote/all/' + user;
            console.log("url: " + url);
            console.log("token: " + token);
            const response = await axios.get(url, {
                headers: {
                    'Authorization': token
                }
            });
            console.log("Quotes: ", response.data);
            setQuotes(response.data);
        } catch (error) {
            console.error("Error fetching quotes: " + error);
            throw new Error('Failed to retrieve quotes.');
        }
    };

    const verifyToken = (accessToken) => {
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                console.log("verifyToken: ", user);
                setUid(user.uid);
                setLoginBtnText("Logout");
                setValidToken(true);
                getQuotes(accessToken, user.uid);
            } else {
                console.error('Error verifying token:');
                setLoginBtnText("Login");
                setAccessToken(null);
                setValidToken(false);
                history("/");
            }
        });
    };

    const handleShowDetails = (quote) => {
        // Implement the logic to show more details
        console.log("Showing details for:", quote.ebayItem.ebayTitle);
        // You might want to navigate to a new page or open a modal here
    };

    return (
        <div>
            <Header loginBtnText={loginBtnText} navBarColour={"dark"}/>
            <MDBContainer className="px-4">
                {validToken ? (
                    <MDBContainer>
                        <MDBRow className="gx-5">
                            <div className='w-100'><br/></div>
                        </MDBRow>
                        <MDBRow className="gx-5">
                            <MDBCol>
                                <div className="p-3"><h2>Welcome back</h2></div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="gx-5">
                            <div className='w-100'><hr/></div>
                        </MDBRow>
                        <MDBRow className="gx-5">
                            {quotes.map((quote, index) => (
                                <MDBCol key={index} size="12" md="6" lg="4" className="mb-4">
                                    <MDBCard>
                                        <MDBCardImage src={quote.ebayItem.ebayMainImages} position="top" alt={quote.ebayItem.ebayTitle} />
                                        <MDBCardBody>
                                            <MDBCardTitle>{quote.ebayItem.ebayTitle}</MDBCardTitle>
                                            <MDBCardText>
                                                City: {quote.ebayItem.city}<br/>
                                                Price: £{quote.ebayItem.price}<br/>
                                                Number of Bids: {quote.ebayItem.bidCount || 0}
                                            </MDBCardText>
                                            <MDBBtn onClick={() => handleShowDetails(quote)}>Show More Details</MDBBtn>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            ))}
                        </MDBRow>
                    </MDBContainer>
                ) : (
                    null
                )}
            </MDBContainer>
        </div>
    );
}

export default Main;